import * as React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Layout, Seo } from "../components";
import { mq } from "../styles/GlobalStyles";

const Heading = styled.h2(
  mq({
    marginTop: "0",
    marginBottom: ["32px", "64px"],
  })
);

const P = styled.p(
  mq({
    marginBottom: ["24px", "48px"],
  })
);

const NotFoundPage = () => {
  return (
    <Layout>
      <>
        <Seo title="ページが見つかりません" />
        <Heading>お探しのページが見つかりませんでした</Heading>
        <P>
          <br />
          お探しのページが見つかりませんでした。
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>
          <br />
          URLをお確かめの上、もう一度アクセスしてください。
        </P>
        <Link to="/">トップへ戻る</Link>
      </>
    </Layout>
  );
};

export default NotFoundPage;
